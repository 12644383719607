.page-wrapper {
	max-width: 100vw;
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: linear-gradient(to top right, #264cd7, #9da5ee);

}

.contactCard {
	display: flex;
    align-items: center;
    justify-content: space-around;
	padding: 1rem;
}

.portrait {
  	max-width: 100%;
	width: 20rem;
	height: auto;
	border-radius: 10%;
	box-shadow: 0 0 2rem 0.5rem rgba(0, 0, 0, 0.2);
}

.workCard  {
	padding: 1rem;
}

/* ----- Media queries -----------*/
@media only screen and (min-width: 868px) {
  .contactCardInfo {
    width: 60%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (max-width: 867px) {

  .contactCardInfo {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-items: left;
  }
}