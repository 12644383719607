@media only screen and (min-width: 868px) {
    .main {
        flex-direction: row;
        max-width: 60rem;
    }

}

@media only screen and (max-width: 867px) {
    .main {
        width: 85%;
        flex-direction: column;
    }

}