

.card {
    border-radius: 3rem;
    height: auto;
    background-color: rgb(179, 179, 179, 0.5);
    backdrop-filter: blur(2rem);
    box-shadow: 0 0 3rem 1rem rgba(0, 0, 0, 0.2);

}


@media only screen and (min-width: 868px) {
    .card {
        margin: 8rem 4rem;
    }
}

@media only screen and (max-width: 867px) {
    .card {
        margin: 4rem 2rem;
    }
}