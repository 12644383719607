body {
  margin: 0;
  color: #fff;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 450;
  font-size: 115%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: baseline;
  background-color: #9da5ee;
}

.bold {
  font-weight: bolder;
}

p {
  font-size: 1.3rem;
}

@media only screen and (min-width: 868px) {
  h1 {
    font-size: 2.5rem;
  }

}

@media only screen and (max-width: 867px) {
  h1 {
    font-size: calc(1.375rem + 1.5vw);
  }
}

/* -------------- Flex box config --------------- */
.flex {
  display: flex;
}
.row {
  flex-direction: row;
}
.column {
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
.justify-around {
  justify-content: space-around;
}
.justify-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}

/* ------------- Padding and margin -------------- */
.p1 {
  padding: 1rem;
}
.py1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px1 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.p2 {
  padding: 2rem;
}
.py2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.px2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.m1 {
  margin: 1rem;
}

.my1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx1 {
  margin-left: 1rem;
  margin-right: 1rem;
}
